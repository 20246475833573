import { Link, Flex, FlexProps, Button } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

import { useDevice } from '~/hooks';
import theme from '~/theme';
import { LanguageMenu } from '~/theme/components/';
import { Company, MoreVert } from '~/theme/icons';

export interface HeaderProps extends FlexProps {
	onOpen(): void;
}

const Header: React.FC<HeaderProps> = ({ onOpen, ...props }) => {
	const { isMobileDevice } = useDevice();

	return (
		<Flex
			bgColor="purple.300"
			flexDirection="row"
			justifyContent="space-between"
			alignItems="center"
			color="white"
			position="fixed"
			zIndex={theme.zIndices.docked + 1}
			width="100%"
			height="4.375rem"
			top="0"
			px={['6', '8']}
			{...props}
		>
			<Link
				as={NavLink}
				to="/"
				sx={{
					'&:link, &:visited, &:hover, &:active': {
						color: 'inherit',
					},
				}}
			>
				<Company width="28" height="9" />
			</Link>
			<LanguageMenu isTransparent />
			{isMobileDevice && (
				<Button variant="terciary" rightIcon={<MoreVert />} colorScheme="white" onClick={onOpen as () => void}>
					Mais
				</Button>
			)}
		</Flex>
	);
};

export default Header;
