import { forwardRef, Children, useState, useImperativeHandle, useEffect, useRef, useCallback, useMemo } from 'react';

import {
	Box,
	Popover,
	PopoverContent,
	PopoverTrigger as OrigPopoverTrigger,
	useDisclosure,
	useRadioGroup,
	useBoolean,
	InputGroup,
	InputRightElement,
	Input,
	Button,
	ButtonProps,
	useFormControlContext,
} from '@chakra-ui/react';
import type { Token } from '@chakra-ui/styled-system/dist/declarations/src/utils/types';
import { StringOrNumber } from '@chakra-ui/utils';
import type { Property } from 'csstype';

import { useNamespace } from '~/hooks';
import { ChevronDown, ChevronUp, Search } from '~/theme/icons';

import Scrollbar from '../Scrollbar';
import { OptionProps } from './Option';

export interface SelectProps extends Omit<ButtonProps, 'onChange' | 'defaultChecked' | 'value'> {
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
	onChangeValue?: (value: StringOrNumber) => void;
	searchable?: boolean | string;
	value?: StringOrNumber;
}

const PopoverTrigger: React.FC<{ children: React.ReactNode }> = OrigPopoverTrigger;

const Select = forwardRef<HTMLInputElement, SelectProps>(
	({ name, onChange, onChangeValue, searchable, placeholder, children, maxHeight, ...props }, ref) => {
		const cache = useRef({} as { [key: string]: any });
		const triggerRef = useRef<HTMLButtonElement>(null);
		const { isOpen, onOpen, onClose } = useDisclosure();
		const [width, setWidth] = useState<Token<Property.Width | number, 'sizes'>>('auto');
		const [query, setQuery] = useState('');
		const [isPicked, { on }] = useBoolean();
		const context = useFormControlContext();

		const { translate } = useNamespace('select');

		const { getRootProps, getRadioProps, setValue, ...radio } = useRadioGroup({
			name,
			defaultValue: props.defaultValue as StringOrNumber,
			value: props.value as StringOrNumber,
		});

		const Icon = useMemo(() => (isOpen ? ChevronUp : ChevronDown), [isOpen]);

		const handleClose = useCallback(() => {
			setQuery('');
			onClose();
		}, [onClose]);

		const handleResize: MutationCallback = useCallback(() => {
			const trigger = triggerRef.current;

			if (document.contains(trigger) && trigger) {
				setWidth(trigger.clientWidth);
			}
		}, []);

		const handleKeyPressed: React.KeyboardEventHandler<HTMLButtonElement> = (event) => {
			switch (event.code) {
				case 'Enter':
					onOpen();
					break;
				case 'Escape':
					onClose();
					break;
			}
		};

		useEffect(() => {
			if ((props.value || props.defaultValue) && !isPicked) {
				on();
			}
		}, [isPicked, on, props.defaultValue, props.value]);

		useEffect(() => {
			const observer = new MutationObserver(handleResize);

			observer.observe(document, { childList: true, subtree: true });

			return () => {
				observer.disconnect();
			};
		}, [handleResize]);

		useEffect(() => {
			Children.forEach(children as React.ReactElement, ({ props }: React.ReactElement<OptionProps<StringOrNumber>>) => {
				if ('defaultChecked' in props && props.defaultChecked) {
					setValue(props.value);
				}

				cache.current[props.value] = props.children;
			});
		}, [children, setValue]);

		useImperativeHandle(ref, () => radio.ref.current, [radio.ref]);

		return (
			<Popover placement="bottom-start" {...{ isOpen, onOpen, onClose: handleClose }}>
				<PopoverTrigger>
					<Button
						tabIndex={0}
						ref={triggerRef}
						as="div"
						data-xxx
						display="flex"
						justifyContent="space-between"
						variant="terciary"
						bgColor="white"
						layerStyle={isPicked ? 'valid' : 'invalid'}
						borderRadius="lg"
						borderColor={props.isDisabled ? '' : 'initial'}
						pointerEvents={isOpen ? 'none' : 'auto'}
						padding="4"
						height="12"
						rightIcon={<Icon boxSize="5" color={props.isDisabled ? 'gray.700' : 'purple.300'} />}
						color="gray.700"
						className="select-label"
						_disabled={{
							bgColor: 'gray.100',
							opacity: 0.8,
							pointerEvents: 'none',
						}}
						isFullWidth
						isTruncated
						isDisabled={context?.isDisabled}
						onKeyDown={handleKeyPressed}
						{...props}
					>
						{cache.current[radio.value] ?? translate(placeholder as 'select@select-placeholder')}
					</Button>
				</PopoverTrigger>
				<PopoverContent
					boxShadow="lg"
					bgColor="white"
					borderColor="transparent"
					borderRadius="lg"
					_focus={{
						outline: 'none',
					}}
					{...{ width, ...getRootProps() }}
				>
					<Box py="2.5" px="4">
						{searchable && (
							<InputGroup my="4">
								<Input
									placeholder={typeof searchable === 'boolean' ? translate('select@search-placeholder') : searchable}
									onInput={({ target }) => setQuery((target as HTMLInputElement).value)}
									value={query}
									required
								/>
								<InputRightElement>
									<Search color="purple.300" boxSize="5" />
								</InputRightElement>
							</InputGroup>
						)}
						<Scrollbar maxHeight={maxHeight}>
							{isOpen &&
								Children.map(
									children as React.ReactElement,
									({ props: { value, ...rest }, ...child }: React.ReactElement<OptionProps<StringOrNumber>>) => {
										const regExp = new RegExp(query as string, 'gim');

										if (query && !String(rest.children).match(regExp)) return null;

										const { isChecked: checked, ...props } = getRadioProps({
											value,
											onClick(event) {
												setValue(value);
												onChangeValue?.(value);
												onChange?.(event as unknown as React.ChangeEvent<HTMLInputElement>);
												handleClose();
												on();
											},
											onKeyDown(event) {
												if (event.code === 'Enter') {
													setValue(value);
													onChangeValue?.(value);
													onChange?.(event as unknown as React.ChangeEvent<HTMLInputElement>);
													handleClose();
													on();
												}
											},
										});

										return {
											...child,
											props: {
												value,
												checked,
												...rest,
												...props,
											},
											key: value,
										};
									}
								)}
						</Scrollbar>
					</Box>
				</PopoverContent>
			</Popover>
		);
	}
);

Select.displayName = 'Select';

Select.defaultProps = {
	maxHeight: '64',
	placeholder: 'select@select-placeholder',
};

export { default as Option } from './Option';
export * from './Option';

export default Select;
