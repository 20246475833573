import { Flex } from '@chakra-ui/react';
import { Outlet, useOutletContext } from 'react-router-dom';

import { useAuth, useProduct, useQueryParam } from '~/hooks';
import { SupportButton } from '~/theme/components';

import { Container, Outdoor } from './components';

function Layout() {
	const params = useQueryParam('product');
	const auth = useAuth();
	const product = useProduct();

	if (params.product && !product.id) {
		return null;
	}

	return (
		<>
			<Container src={product.background_image}>
				{(goBack) => (
					<Flex direction={['column-reverse', 'row']} width="100%" gap={['6', '8.125rem']} alignItems="start">
						<Flex
							width="100%"
							maxWidth={['100%', 'md']}
							minWidth={['100%', 'md']}
							minHeight="2xs"
							px={['6', '12']}
							py={['8', '12']}
							borderRadius="3xl"
							bgColor="white"
							alignItems="center"
							direction="column"
						>
							<Flex gridRowGap={['4', '8']} direction="column" position="relative" width="100%" height="100%">
								<Outlet context={{ ...product, auth, goBack }} />
							</Flex>
						</Flex>
						<Outdoor />
					</Flex>
				)}
			</Container>
			<SupportButton />
		</>
	);
}

export function useLoggedOut() {
	const params = useOutletContext<
		ReturnType<typeof useProduct> & {
			auth: ReturnType<typeof useAuth>;
			goBack(): void;
		}
	>();

	return params;
}

export default Layout;
