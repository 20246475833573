import { useEffect, useState } from 'react';

import { ComponentWithAs, IconProps } from '@chakra-ui/react';

import bifrostHttp from '~/services/bifrostHttp';
import { F, Talks } from '~/theme/icons';

export enum ProductKey {
	ACADEMY = 'fluency_academy',
	ACADEMY_WAIT_LIST = 'fluency_academy_wait_list',
	TALKS = 'talks_talks',
}

export type Product = {
	Icon: ComponentWithAs<'svg', IconProps>;
	title: string;
	to: string;
	key: ProductKey;
};

const DEFAULT_PRODUCTS = {
	[ProductKey.TALKS]: {
		Icon: Talks,
		title: 'Talks',
		to: `https://talks${import.meta.env.VITE_APP_DOMAIN}/student/profile`,
		key: ProductKey.TALKS,
	},
	[ProductKey.ACADEMY]: {
		Icon: F,
		title: 'Academy',
		to: `https://academy${import.meta.env.VITE_APP_DOMAIN}`,
		key: ProductKey.ACADEMY,
	},
	[ProductKey.ACADEMY_WAIT_LIST]: {
		Icon: F,
		title: 'Academy',
		to: 'https://hub.fluency.io/br/fa/lista-de-espera',
		key: ProductKey.ACADEMY_WAIT_LIST,
	},
};

// Hook that sets product list to show on profile nav bar
const useProducts = () => {
	const [products, setProducts] = useState<Array<Product> | null>(null);

	useEffect(() => {
		const fetchData = async () => {
			const prods: Array<Product> = [];

			// Alwayd add Talks
			prods.push(DEFAULT_PRODUCTS[ProductKey.TALKS]);

			try {
				const response = await bifrostHttp({ url: '/programs', method: 'GET' });

				if (response.status > 300) {
					throw new Error(response.statusText);
				}

				if (response.data.programs.length > 0) {
					prods.push(DEFAULT_PRODUCTS[ProductKey.ACADEMY]);
				} else {
					throw new Error('No Academy contract');
				}
			} catch (error) {
				// Add Academy wait list redirect if there's no contract or request was a failure
				prods.push(DEFAULT_PRODUCTS[ProductKey.ACADEMY_WAIT_LIST]);
			} finally {
				// Set states
				setProducts(prods);
			}
		};

		fetchData();
	}, []);

	return { products };
};

export default useProducts;
